import React from 'react';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import PokemonView from './views/pokemon'
import GanniView from './views/ganni'

// function App() {
//   console.log(window.innerHeight)
//   let patchCode = window.location.pathname
//   console.log('patchCode', patchCode)

//   let patch = null
//   if(patchCode === '/pb'){
//     patch = 'bg_pokeball'
//   } else if(patchCode === '/pk'){
//     patch = 'bg_pikachu'
//   } else if(patchCode === '/sl'){
//     patch = 'bg_snorlax'
//   }

//   console.log('Patch', patch)
//   return (
//     <div className={"splashPage " + patch} style={{height: window.innerHeight}}>
//       <div className="slides">
//         <div className="slide_top">
//           <div className="logos">
//             <img src={logo_levis} alt="levis" className="logo_levis"/>
//             <img src={logo_pokemon} alt="pokemon" className="logo_pokemon"/>
//           </div>
//         </div>
//         <div className="slide_bottom">
//           <h1>Thanks for tapping</h1>
//           <p>Coming soon...</p>
//         </div>
//       </div>
//     </div>
//   );
// }

function App() {
  return(
    <Router>
      <Route path='/pb' component={() => <PokemonView patch='bg_pokeball' />} />
      <Route path='/pk' component={() => <PokemonView patch='bg_pikachu' />} />
      <Route path='/sl' component={() => <PokemonView patch='bg_snorlax' />} />
      <Route path='/ganni' component={() => <GanniView />} />
    </Router>
  );
}

export default App;
