import React from 'react';
// import '../Ganni.scss';

import logo_levis from '../assets/levis.png'
import logo_ganni from '../assets/ganni.png'
import logo_cphfs from '../assets/cphfs.png'
import x from '../assets/x.svg'

function GanniView() {
  
    return (
      <div className={"splashPage bg_cphfs"} style={{height: window.innerHeight}}>
        <div className="slides">
          <div className="slide_top ganni_slide_top">
          <div className="logos">
                <img src={logo_levis} alt="levis" width="50%" className="ganni_logo_levis"/>
                <img src={x} alt="levis" className="ganni_x"/>
                <img src={logo_ganni} alt="Ganni" width="50%" className="ganni_logo_ganni"/>
              </div>
          </div>
          <div className="slide_bottom ganni">
            <img src={logo_cphfs} alt="cfs" />
            <p>COMING SOON</p>
          </div>
        </div>
      </div>
    );
  }

export default GanniView