import React from 'react';
import '../App.scss';
import logo_levis from '../assets/levis.png'
import logo_pokemon from '../assets/pokemon.png'

function PokemonView({patch}) {
    // console.log(window.innerHeight)
    // let patchCode = window.location.pathname
    // console.log('patchCode', patchCode)
  
    // let patch = null
    // if(patchCode === '/pb'){
    //   patch = 'bg_pokeball'
    // } else if(patchCode === '/pk'){
    //   patch = 'bg_pikachu'
    // } else if(patchCode === '/sl'){
    //   patch = 'bg_snorlax'
    // }
  
    console.log('Patch', patch)
    return (
      <div className={"splashPage " + patch} style={{height: window.innerHeight}}>
        <div className="slides">
          <div className="slide_top">
            <div className="logos">
              <img src={logo_levis} alt="levis" className="logo_levis"/>
              <img src={logo_pokemon} alt="pokemon" className="logo_pokemon"/>
            </div>
          </div>
          <div className="slide_bottom">
            <h1>Thanks for tapping</h1>
            <p>Coming soon...</p>
          </div>
        </div>
      </div>
    );
  }

export default PokemonView